import ProgressBar from 'react-bootstrap/ProgressBar'
import React, {useState, useEffect} from "react"
import axios from 'axios'

const zerovalue = {
    run: {
        distance: 0,
        target: 8000,
        percentage: 0
    },
    press: {
        total: 0,
        target: 80000,
        percentage: 0
    },
    sit: {
        total: 0,
        target: 80000,
        percentage: 0
    }
}

const TrackSqn = () => {
    const [data, setData] = useState(zerovalue)

    useEffect(() => {
        axios.get('https://evening-brook-47197.herokuapp.com/sheet')
            .then(response => {
                console.log(response)
                setData(response.data)
            })
    }, [])

    return(
        <div>
            <h5>Running/Walking Distance</h5>
            <ProgressBar striped animated now={data.run.percentage} label={`${data.run.distance}/8000km`}/>
            <h5>Press-ups Total</h5>
            <ProgressBar striped animated now={data.press.percentage} label={`${data.press.total}/80000`}/>
            <h5>Sit-ups Total</h5>
            <ProgressBar striped animated now={data.sit.percentage} label={`${data.sit.total}/80000`}/>
        </div>
    )
}

export default TrackSqn;
