import './App.scss'

import Top from './Top/Top'
import TrackSqn from './Track/TrackSqn'


const App = () => {

    return (
        <div>
            <Top/>
            <h2>Squadron Totals</h2>
            <br/>
            <TrackSqn/>
            <br/>
            <br/>
        </div>
    )
}

export default App;
