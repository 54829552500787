import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'

const Top = () => (
    <Navbar className="bg-suas" sticky="top" variant="dark">
        <Navbar.Brand>
            <img
                src="https://southamptonuas.co.uk/track/crestsmall.png"
                alt=""
                width="50"
            />{' '}
            SUAS 80th Anniversary Challenge Tracker
        </Navbar.Brand>

        <Nav className="ml-auto">
            <Nav.Link href="https://www.justgiving.com/fundraising/suas80">Donate</Nav.Link>
        </Nav>
    </Navbar>
)

export default Top;
